<template>
    <div class="tiw__closeMenu">
        <ul class="tiw__closeMenu__list">
            <li class="tiw__closeMenu__item">
                <RefreshIcon class="tiw__closeMenu__icon" />
                <span class="tiw__closeMenu__text" @click="onRestart">
                    {{ useTranslate("tiw__restart") }}
                </span>
            </li>
            <li
                v-if="TIWStore.CURRENT_STEP === 'operatorExecuting'"
                class="tiw__closeMenu__item"
                @click="TIWStore.rollDownWidget"
            >
                <InternalIcon class="tiw__closeMenu__icon" />
                <span class="tiw__closeMenu__text">
                    {{ useTranslate("tiw__rollDown") }}
                </span>
            </li>
            <li class="tiw__closeMenu__item tiw__closeMenu__item_danger">
                <CloseIcon variant="outline" class="tiw__closeMenu__icon" />
                <span class="tiw__closeMenu__text" @click="onCancel">
                    {{ useTranslate("tiw__cancel") }}
                </span>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
export default {
    name: "CloseMenu",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [CloseMenu]
 * Меню виджета при закрытии
 */

// Icons
import RefreshIcon from "@/ui/icons/refresh.vue";
import InternalIcon from "@/ui/icons/internal.vue";
import CloseIcon from "@/ui/icons/close.vue";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";

// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";
import useStoreResetter from "@/hooks/useStoreResetter/useStoreResetter";
import { useAnalyticsEventTiModel } from "@/hooks/useAnalytics/useAnalytics";

const TIWStore = useTIWStore();

const onRestart = () => {
    useStoreResetter();
    TIWStore.INJECTED_TIW.current_api.onDrop();
    TIWStore.switchStep("chooseDevice");
    useAnalyticsEventTiModel();
};

// Здесь норм
const onCancel = () => {
    onRestart();
    TIWStore.closeWidget();
};
</script>

<style lang="scss" src="./closeMenu.scss"></style>
