<template>
    <div class="tiw__loadPanel">
        <div class="tiw__loadPanel__container">
            <figure class="tiw__loadPanel__figure" @click="onClickImagePreview">
                <img
                    class="tiw__loadPanel__img"
                    :src="props.data.content"
                    alt="Screen front imei preview"
                />
            </figure>

            <div class="tiw__loadPanel__body">
                <!-- Подсказка [решили что не будет] -->
                <!-- <Button
                    class="tiw__loadPanel__hint-icon"
                    type="button"
                    variant="unset"
                >
                    <QuestionIcon />
                </Button> -->

                <!-- Заголовок -->
                <h4 class="tiw__loadPanel__title">
                    {{ props.title }}
                </h4>

                <!-- Подсказка -->
                <p v-if="props.hint" class="tiw__loadPanel__hint" v-html="props.hint"></p>

                <!-- Alert -->
                <div
                    v-if="props.data.message"
                    :class="[
                        'tiw__loadPanel__note',
                        `tiw__loadPanel__note_${props.data.message.type}`,
                    ]"
                >
                    <CloseIcon
                        v-if="props.data.message.type === 'error'"
                        variant="outline"
                        class="tiw__loadPanel__note__icon"
                    />
                    <AlertIcon v-else class="tiw__loadPanel__note__icon" />

                    <span class="tiw__loadPanel__note__text">
                        {{ props.data.message.text }}
                    </span>
                </div>

                <template v-if="!props.data.wasUploaded">
                    <input
                        :id="props.lineId"
                        ref="inputRef"
                        type="file"
                        :name="props.name"
                        accept="image/png, image/jpeg, image/jpg, image/bmp, image/heic"
                        hidden
                        :data-name="props.nameData"
                        @change="onLoadHandler"
                    />
                </template>

                <!-- Загрузить фото -->
                <template v-if="!props.data.hideLoadButton">
                    <label
                        v-if="!props.data.placeholder"
                        class="tiw__loadPanel__loadLabel"
                        :for="props.lineId"
                        :data-name="props.nameData"
                    >
                        <Button
                            type="file"
                            color="light"
                            as="span"
                            :loading="donwloadPhotoLoading"
                            class="tiw__loadPanel__loadButton"
                        >
                            <span>
                                {{
                                    useTranslate(
                                        "tiw__loadPanel__downloadPhoto"
                                    )
                                }}
                            </span>
                        </Button>
                    </label>
                </template>

                <!-- Загруженное фото -->
                <div
                    v-if="props.data.placeholder"
                    class="tiw__loadPanel__editLabel"
                >
                    <div
                        :class="{
                            tiw__loadPanel__fileInfo: true,
                            tiw__loadPanel__fileInfo_loading:
                                props.isLoadingStatus || props.data.wasUploaded,
                        }"
                    >
                        <CheckIcon />
                        <span>{{ props.data.placeholder }}</span>
                    </div>

                    <template v-if="!props.data.wasUploaded">
                        <template v-if="!props.isLoadingStatus">
                            <Button
                                type="button"
                                color="light"
                                class="tiw__loadPanel__removeButton"
                                @click="onRemoveHandler($event, props.nameData)"
                            >
                                <TrashIcon />
                            </Button>
                        </template>
                    </template>
                </div>
            </div>
        </div>

        <!-- Комментарий оператора -->
        <div v-if="props.data.comment" class="tiw__loadPanel__comment">
            <span>
                {{ props.data.comment.description }}
            </span>
            <ul v-if="props.data.comment.list">
                <li
                    v-for="(item, ind) in props.data.comment.list"
                    :key="'comment_' + ind"
                >
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "LoadPanel",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Load Panel]
 * Поле загрузки фотографий
 */

// Components
import Button from "@/ui/button/button.vue";

// Icons
import TrashIcon from "@/ui/icons/trash.vue";
import CheckIcon from "@/ui/icons/check.vue";
import CloseIcon from "@/ui/icons/close.vue";
import AlertIcon from "@/ui/icons/alert.vue";

// Types
import type { I_LoadPanel } from "./loadPanel.types";
import type { I_DownloadPhotos_Field_Names } from "@/stores/downloadPhotos/downloadPhotos.types";

// Hooks
import { ref } from "vue";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import { isNeedConvertImageToJpeg, convertImageToJpeg } from "@/helpers/convertImageToJpeg";

// Stores
import { useImageFullScreenStore } from "@/stores/imageFullScreen/imageFullScreen";

const props = defineProps<I_LoadPanel>();

const inputRef = ref<HTMLInputElement>();
const donwloadPhotoLoading = ref<boolean>(false);

const ImageFullScreenStore = useImageFullScreenStore();

const onClickImagePreview = () => {
    ImageFullScreenStore.setImageUrl(props.data.content);
    ImageFullScreenStore.show();
};

const onLoadHandler = async (e: Event & { target: HTMLInputElement }) => {
    if (isNeedConvertImageToJpeg(e.target)) {
        donwloadPhotoLoading.value = true;
        await convertImageToJpeg(e.target);
        donwloadPhotoLoading.value = false;
    }

    props.onLoad(e, props.nameData);
};

const onRemoveHandler = (
    e: Event & { target: HTMLButtonElement },
    inputName: I_DownloadPhotos_Field_Names
) => {
    inputRef.value.value = "";
    props.onRemove(e, inputName);
};
</script>

<style lang="scss" src="./loadPanel.scss"></style>
