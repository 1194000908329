// Types
import type { I_CalculatedDeviceData } from "@/stores/howYouRateDevice/howYouRateDevice.types";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";

// Hooks
import parseStringPrice from "@/helpers/parseStringPrice";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import { deviceGradeIsEquals } from "@/helpers/deviceGrade";

interface I_useTradeInCalculation_payload {
    tradeInPrice: string; // Рассчитать с этой ценой выкупа устройства
}

/** Рассчитать Trade-In чек */
export default function useTradeInCalculation(
    payload = {} as I_useTradeInCalculation_payload
): void {
    const TIWStore = useTIWStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();

    const panelTradeInPrice =
        payload.tradeInPrice ||
        HowYouRateDeviceStore.registeringDeviceData.preliminaryPriceGradeInfo.gradePriceInfo
            .find((el) => deviceGradeIsEquals(el.grade, HowYouRateDeviceStore.grade))
            .price.toString();

    const panelCostOriginal: number = parseStringPrice(TIWStore.INJECTED_GOOD.panelPrice) -
            parseStringPrice(panelTradeInPrice);

    const panelCost: number = (panelCostOriginal > TIWStore.minOrderPrice ? panelCostOriginal : TIWStore.minOrderPrice);

    const requestResult: I_CalculatedDeviceData = {
        ...TIWStore.INJECTED_GOOD,

        panelCostOriginal: panelCostOriginal,
        panelCost: (panelCost).toLocaleString("ru-RU"),
        panelTradeInSale:
            TIWStore.CURRENT_STEP === "successfulEvaluation"
                ? {
                      tradeInSale:
                          parseStringPrice(panelTradeInPrice).toLocaleString(
                              "ru-RU"
                          ),
                      tradeInInfo: useTranslate(
                          "tiw__calculatedDevice__price_list_tradeInInfo"
                      ),
                  }
                : parseStringPrice(panelTradeInPrice).toLocaleString("ru-RU"),
        panelActionSale: null,
    };

    // Проверяем на наличие дополнительной скидки (акции)
    if (
        HowYouRateDeviceStore.registeringDeviceData.buyoutTopUp
            .specialGrading &&
        HowYouRateDeviceStore.registeringDeviceData.buyoutTopUp.specialGrading
            .length
    ) {
        // Проверяем есть ли ранее определённый грейд после ответа на вопрос в списке акционных
        const findActionSale =
            HowYouRateDeviceStore.registeringDeviceData.buyoutTopUp.specialGrading[0].grades
                .find((el) => deviceGradeIsEquals(el.gradeCode, HowYouRateDeviceStore.grade));

        if (findActionSale) {
            requestResult.panelActionSale = {
                description: useTranslate(
                    "tiw__calculatedDevice__price_list_additionalSale_valid"
                )
                    .replace(
                        "[[START]]",
                        HowYouRateDeviceStore.registeringDeviceData.buyoutTopUp
                            .specialGrading[0].options[0].validFrom
                    )
                    .replace(
                        "[[END]]",
                        HowYouRateDeviceStore.registeringDeviceData.buyoutTopUp
                            .specialGrading[0].options[0].validTill
                    ),
                iconUrl: null,
                iconSvg:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M6.58872 21.8312C5.48713 20.5794 4.38559 19.2775 3.68456 17.7253C2.98357 16.0729 2.78327 14.1702 3.13378 12.3677C3.4843 10.5651 4.28541 8.86265 5.43709 7.4106C5.33696 8.6624 5.78761 9.96424 6.6388 10.9156C6.13807 8.51218 6.93923 5.90845 8.54151 4.00575C10.1438 2.10305 12.347 0.8512 14.6502 0C13.4986 1.20172 13.3484 3.10442 13.799 4.70674C14.2497 6.30901 15.201 7.71102 16.0522 9.16308C16.9034 10.6151 17.7046 12.1173 17.7547 13.7696C18.2053 12.9184 18.706 12.0672 19.0065 11.1159C19.3069 10.1645 19.4571 9.16308 19.2068 8.21171C20.1081 9.3133 20.6088 10.4148 20.8591 11.8169C21.1095 13.2189 21.0594 14.6709 20.9092 16.0729C20.7089 17.6252 20.3084 19.2275 19.4572 20.5293C18.4558 22.0815 16.8534 23.1831 15.1009 23.7839C11.9964 24.3347 8.94206 23.8841 6.58872 21.8312Z" fill="#FD4B00"/><path d="M10.3941 23.6826C14.5 25.2849 18.5558 20.428 16.3025 16.6726C16.3025 16.6225 16.2525 16.6225 16.2525 16.5725C16.4528 18.6254 15.952 20.1276 15.0007 20.9788C15.5014 19.7771 15.1509 18.3751 14.55 17.1734C13.9491 16.0217 13.0979 14.9702 12.447 13.8686C11.7961 12.717 11.2954 11.4151 11.5958 10.1133C10.3941 11.0146 9.54289 12.3665 9.19237 13.8186C8.84186 15.2706 9.04216 16.8729 9.6931 18.2249C8.94203 17.6741 8.39126 16.7728 8.34117 15.8214C7.54001 16.7728 7.03933 18.0246 7.08937 19.2764C7.08942 21.2292 8.6416 22.9816 10.3941 23.6826Z" fill="#FFD15C"/></svg>',
                actionSale:
                    HowYouRateDeviceStore.registeringDeviceData.buyoutTopUp.specialGrading[0].grades
                        .find((el) => deviceGradeIsEquals(el.gradeCode, HowYouRateDeviceStore.grade))
                        .additionalDiscount.toLocaleString("ru-RU"),
            };

            // requestResult.panelCost = (
            //     parseStringPrice(requestResult.panelCost) -
            //     parseStringPrice(requestResult.panelActionSale.actionSale)
            // ).toLocaleString("ru-RU");

            requestResult.panelCostOriginal = parseStringPrice(requestResult.panelCost) -
                parseStringPrice(requestResult.panelActionSale.actionSale);

            requestResult.panelCost = (requestResult.panelCostOriginal > TIWStore.minOrderPrice ?
                requestResult.panelCostOriginal :
                TIWStore.minOrderPrice
            ).toLocaleString("ru-RU");
        }
    }

    HowYouRateDeviceStore.calculatedDeviceData = requestResult;
}
