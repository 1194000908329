/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */

import { createApp } from "vue";
import { createPinia } from "pinia";
import TIW from "./TradeInWidget.vue";
import { warn } from "@/helpers/consoleColors";

const pinia = createPinia();

class TIWidget {
    #default_settings = {
        locale: "ru",
        domain: "",
        // theme: "light",
        root_element: "TIWidget",
    };

    // #default_translations = {
    //     translations: {
    //         ru: {},
    //         en: {},
    //     },
    // };

    #default_api = {
        onInit: null,
        onLoad: null,
        onShow: null,
        onHide: null,
        onCalc: null,
        onCart: null,
        onDrop: null,
        tradeInWithGood: null,
    };

    current_settings = {};
    // current_translations = {};
    current_api = {};

    #init_widget(with_settings) {
        this.current_settings = {
            ...this.#default_settings,
            ...with_settings.settings,
        };

        this.current_api = {
            ...this.#default_api,
            ...with_settings.api,
        };

        const app = createApp(TIW, this.current_settings);

        app.use(pinia);
        app.provide("widget", this);
        app.provide("appInstance", app);

        // watch(
        //     pinia.state,
        //     (state) => {
        //         // сохранять все состояние в local storage при каждом его изменении
        //         localStorage.setItem("TIWState", JSON.stringify(state));
        //     },
        //     { deep: true }
        // );

        app.mount("#" + this.current_settings.root_element);
    }

    constructor(init_settings) {
        if (TIWidget.WIDGET_INSTANCE) {
            console.log(
                "%c[TIWidget] -> RETURN INITIALIZED INSTANCE FROM CACHE",
                warn
            );
            return TIWidget.WIDGET_INSTANCE;
        }

        this.#init_widget(init_settings);

        TIWidget.WIDGET_INSTANCE = this;
    }

    static WIDGET_INSTANCE = null;
}

window.TIWidget = TIWidget;
