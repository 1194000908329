<template>
    <Modal>
        <template #header>
            <h2>
                {{ useTranslate("tiw__downloadPhotos__title") }}
            </h2>
            <!-- <p
                class="tiw__step__downloadPhotos__info"
                v-html="useTranslate('tiw__downloadPhotos__qrInfo')"
            /> -->
        </template>

        <div class="tiw__step__downloadPhotos">
            <QRPanel :qr-preview="DownloadPhotosStore.qrPath" />

            <form ref="FormRef" class="downloadPhotosForm" name="downloadPhotosForm">
                <template
                    v-for="(
                        lineData, index
                    ) in DownloadPhotosStore.fieldsLineData"
                    :key="DownloadPhotosStore.fieldNamesData[index]"
                >
                    <LoadPanel
                        :data="
                            DownloadPhotosStore[
                                DownloadPhotosStore.fieldNamesData[index]
                            ]
                        "
                        name="file"
                        :name-data="DownloadPhotosStore.fieldNamesData[index]"
                        :line-id="lineData.lineId"
                        :title="lineData.sideDescription"
                        :hint="lineData.text"
                        :is-loading-status="loadingToNext"
                        :on-load="onLoadHandler"
                        :on-remove="onRemoveHandler"
                    />
                </template>
            </form>

            <div class="tiw_group tiw_group-sb">
                <Button
                    type="button"
                    variant="skeleton"
                    class="tiw__step__downloadPhotos__btn-back"
                    @click="clickToBackHandler"
                >
                    <ArrowIcon />
                    <span>{{ useTranslate("tiw__back") }}</span>
                </Button>
                <Button
                    type="button"
                    class="tiw__step__downloadPhotos__btn-next tiw__btn-next"
                    :disabled="loadingToNext"
                    :loading="loadingToNext"
                    @click="clickToNext"
                >
                    <span>
                        {{ useTranslate("tiw__continue") }}
                    </span>
                </Button>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "DownloadPhotos",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [DownloadPhotos]
 * Модальное окно / экран "Загрузить фотографии"
 */

// Components
import Modal from "@/components/modal/modal.vue";
import Button from "@/ui/button/button.vue";
import QRPanel from "@/components/qrPanel/qrPanel.vue"; // Скрыто до след. итерации
import LoadPanel from "@/components/loadPanel/loadPanel.vue";

// Icons
import ArrowIcon from "@/ui/icons/arrow.vue";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";

// Hooks
import { ref } from "vue";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import usePhotoDownloader from "@/hooks/usePhotoDownloader/usePhotoDownloader";
import useValidate from "@/hooks/useValidate";
import it4GetLoadPhotoFields from "@/hooks/requests/it4GetLoadPhotoFields/it4GetLoadPhotoFields";
import it4getPhotoSetId from "@/hooks/requests/it4GetPhotoSetId/it4GetPhotoSetId";
import {
    useAnalyticsEventImei,
    useAnalyticsEventPhotoAssessment,
} from "@/hooks/useAnalytics/useAnalytics";

// Types
import type { I_DownloadPhotos_Field_Names } from "@/stores/downloadPhotos/downloadPhotos.types";

const TIWStore = useTIWStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();
const DownloadPhotosStore = useDownloadPhotosStore();

const useFileValidator = (
    target: HTMLInputElement,
    fieldName: I_DownloadPhotos_Field_Names
) => {
    const validateStatus = useValidate({ target: "file", value: target });

    if (!validateStatus.status) {
        DownloadPhotosStore[fieldName].message = {
            type: "error",
            text: validateStatus.message,
        };
        return;
    } else {
        if (DownloadPhotosStore[fieldName].message) {
            delete DownloadPhotosStore[fieldName].message;
        }
    }

    DownloadPhotosStore[fieldName].placeholder = target.files[0].name;
    DownloadPhotosStore[fieldName].content = URL.createObjectURL(
        target.files[0]
    );
    DownloadPhotosStore[fieldName].isValid = true;
};

// Слушатель и валидатор полей загрузки фотографий
const onLoadHandler = (
    e: Event & { target: HTMLInputElement },
    fieldName: I_DownloadPhotos_Field_Names
) => {
    useFileValidator(e.target, fieldName);
};

const onRemoveHandler = (
    e: Event & { target: HTMLButtonElement },
    inputName: I_DownloadPhotos_Field_Names
) => {
    DownloadPhotosStore.removePhotoFromFiled(inputName);
};

const loadingToNext = ref<boolean>(false);
const FormRef = ref<HTMLFormElement>();

const clickToBackHandler = () => {
    TIWStore.switchStep("enterImei");
    useAnalyticsEventImei();
};

const clickToNext = async () => {
    loadingToNext.value = true;

    // Проверить были ли ранее загружены фото через QR-код
    const loadPhotoFieldsData = await it4GetLoadPhotoFields({
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
        token: TIWStore.WIDGET_DATA.token,
        lang: TIWStore.standardizedLocale,
    });

    // Обновить данные стора
    // DownloadPhotosStore.fieldsLineData = loadPhotoFieldsData;

    loadPhotoFieldsData.forEach((el, ind) =>
        DownloadPhotosStore.qrUploadChecker(el, ind)
    );

    /**
     * Срабатывание watch внутри DownloadPhotosStore – это микротаска походу (но это не точно),
     * в связи с этим компутэд-значение DownloadPhotosStore.isAcceptedToNext было
     * ещё старым (не пересчитанным), на момент работы синхронного кода.
     *
     * Мы создали микротаску, которая отработает ПОСЛЕ отработки WATCH внутри DownloadPhotosStore,
     * и таким образом внутри синхронного кода мы будем иметь пересчитанное компутэд-значение
     *
     * @description Интересная статья по теме
     * @link https://habr.com/ru/companies/yandex/articles/718084/
     */
    queueMicrotask(async () => {
        // Если сразу все фото были загружены переходить сразу к "operatorExecuting"
        if (
            DownloadPhotosStore.fieldsLineData.every(
                (field) => field.wasUploaded
            )
        ) {
            console.log(
                "Все фото были загружены через QR ПЕРЕХОД К operatorExecuting"
            );

            const photoSetData = await it4getPhotoSetId(
                HowYouRateDeviceStore.registeringDeviceData.deviceId,
                {
                    token: TIWStore.WIDGET_DATA.token,
                    lang: TIWStore.standardizedLocale,
                }
            );
            DownloadPhotosStore.photoSetData = photoSetData;

            await new Promise((res) => setTimeout(() => res(1), 2000));

            loadingToNext.value = false;
            TIWStore.switchStep("operatorExecuting");
            useAnalyticsEventPhotoAssessment();
            return;
        }
        // Если сразу все фото НЕ были загружены или частично загружены через QR,
        // и была нажата кнопа "Продолжить"
        else {
            // определить во всех ли нужных полях выбрано фото и если можно приступить к загрузить фото
            if (DownloadPhotosStore.isAcceptedToNext) {
                console.log("ФОТО УСПЕШНО БУДУТ ЗАГРУЖЕНЫ");

                await usePhotoDownloader(FormRef.value);

                TIWStore.switchStep("operatorExecuting");
                useAnalyticsEventPhotoAssessment();
                loadingToNext.value = false;
                return;
            } else {
                DownloadPhotosStore.fieldsLineData.forEach(
                    ({ lineId, wasUploaded }) => {
                        if (wasUploaded) return;
                        useFileValidator(
                            FormRef.value[lineId],
                            FormRef.value[lineId].dataset.name
                        );
                    }
                );
                console.log("ЕСТЬ ПОЛЯ С БЕЗ ФОТО");
                loadingToNext.value = false;
                return;
            }
        }
    });
};
</script>

<style lang="scss" src="./downloadPhotos.scss"></style>
