<template>
    <Modal class="tiw__step__howYouRateDevice">
        <template #header>
            <template v-if="HowYouRateDeviceStore.isBack">
                <h2>{{ HowYouRateDeviceStore.questionData.question }}</h2>
                <p>{{ HowYouRateDeviceStore.questionData.description }}</p>
            </template>
            <template v-else>
                <h2>{{ HowYouRateDeviceStore.questionData.question }}</h2>
            </template>
        </template>

        <div class="tiw__step__howYouRateDevice__body">
            <form name="howYouRateDeviceForm">
                <HandedDevice
                    :device-chain="ChooseDeviceStore.deviceChain"
                    :device-state="
                        !HowYouRateDeviceStore.isBack &&
                        HowYouRateDeviceStore.rate
                    "
                    :device-preview="ChooseDeviceStore.deviceImagePreview"
                    :device-grade="null"
                    :device-no-grade="false"
                    :device-terminated="false"
                    :on-edit="onEditHandler"
                />

                <Notification
                    v-if="HowYouRateDeviceStore.showWarningNotificationMinOrder(TIWStore.minOrderPrice)"
                    type="warning"
                    class="tiw__step__howYouRateDevice__body__notification"
                >
                    {{ useTranslate("tiw__howYouRateDevice__warningNotification__minOrder") }}
                </Notification>

                <CalculatedDevice
                    v-if="!HowYouRateDeviceStore.isBack"
                    :calculated-data="
                        HowYouRateDeviceStore.calculatedDeviceData
                    "
                />

                <div
                    v-if="HowYouRateDeviceStore.isBack"
                    :class="{
                        'tiw__rate-chooser': true,
                        'tiw__rate-chooser_loading': loadingToNext,
                    }"
                >
                    <div
                        v-for="chooserItem in HowYouRateDeviceStore.questionData
                            .options"
                        :key="chooserItem.cid"
                        class="tiw__rate-chooser__element"
                    >
                        <input
                            :id="`howYouRateDeviceForm__rate_${chooserItem.cid}`"
                            v-model="HowYouRateDeviceStore.rate"
                            class="tiw_hidden"
                            type="radio"
                            name="rate"
                            :value="chooserItem.cname"
                        />
                        <label
                            :for="`howYouRateDeviceForm__rate_${chooserItem.cid}`"
                            class="tiw__rate-chooser__item"
                            @click="selectRateHandler($event, chooserItem.cid)"
                        >
                            <figure class="tiw__rate-chooser__figure">
                                <img
                                    :src="chooserItem.imageVariant"
                                    :alt="chooserItem.cname"
                                />
                            </figure>
                            <div class="tiw__rate-chooser__body">
                                <h4 class="tiw__rate-chooser__title">
                                    {{ chooserItem.cname }}
                                </h4>
                                <p class="tiw__rate-chooser__text">
                                    {{ chooserItem.description }}
                                </p>
                            </div>
                        </label>
                    </div>
                </div>
            </form>

            <!-- Инфо-блок с пояснением как получить скидку -->
            <template
                v-if="
                    !HowYouRateDeviceStore.isBack &&
                    !HowYouRateDeviceStore.isNoPhotoSet
                "
            >
                <div class="tiw__step__howYouRateDevice__info">
                    <h3 class="tiw__title">
                        {{ useTranslate("tiw__howYouRateDevice__howGetSale") }}
                    </h3>
                    <p class="tiw__text">
                        {{
                            useTranslate(
                                "tiw__howYouRateDevice__howGetSale_info"
                            )
                        }}
                    </p>
                </div>
                <div class="tiw_group tiw_group-sb">
                    <Button
                        type="button"
                        variant="skeleton"
                        class="tiw__step__howYouRateDevice__btn-back"
                        @click="clickToBackHandler"
                    >
                        <ArrowIcon />
                        <span>{{ useTranslate("tiw__back") }}</span>
                    </Button>
                    <Button
                        type="button"
                        class="tiw__step__howYouRateDevice__btn-next tiw__btn-next"
                        @click="clickToNext"
                    >
                        <span>{{ useTranslate("tiw__continue") }}</span>
                    </Button>
                </div>
            </template>

            <!-- Инфо-блок с пояснением что надо идти в магазин -->
            <template
                v-if="
                    !HowYouRateDeviceStore.isBack &&
                    HowYouRateDeviceStore.isNoPhotoSet
                "
            >
                <div class="tiw__step__howYouRateDevice__info">
                    <h3 class="tiw__title">
                        {{ useTranslate("tiw__howYouRateDevice__howGetSale") }}
                    </h3>
                    <p class="tiw__text">
                        {{
                            useTranslate(
                                "tiw__howYouRateDevice__howGetSale_info_goShop"
                            )
                        }}
                    </p>
                </div>
                <div class="tiw_group tiw_group-sb">
                    <Button
                        type="button"
                        variant="skeleton"
                        class="tiw__step__howYouRateDevice__btn-restart"
                        @click="clickRestartHandler"
                    >
                        <RefreshIcon />
                        <span>{{ useTranslate("tiw__restart") }}</span>
                    </Button>
                    <Button
                        type="button"
                        class="tiw__step__howYouRateDevice__btn-next tiw__btn-close"
                        @click="clickToClose"
                    >
                        <span>{{ useTranslate("tiw__close") }}</span>
                    </Button>
                </div>
            </template>
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "HowYouRateDevice",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [HowYouRateDevice]
 * Модальное окно / экран "Как вы оцениваете ваше устройство"
 * */

// Data
import { GRADES_DATA } from "@/stores/howYouRateDevice/howYouRateDevice.model";

// Components
import Modal from "@/components/modal/modal.vue";
import HandedDevice from "@/components/handedDevice/handedDevice.vue";
import CalculatedDevice from "@/components/calculatedDevice/calculatedDevice.vue";
import Button from "@/ui/button/button.vue";
// "Input" здесь сознательно нативный
import Notification from "@/components/notification/notification.vue";

// Icons
import ArrowIcon from "@/ui/icons/arrow.vue";
import RefreshIcon from "@/ui/icons/refresh.vue";

// Hooks
import { ref } from "vue";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import useTradeInCalculation from "@/hooks/useTradeInCalculation/useTradeInCalculation";
import it4SendAnswerQuestion from "@/hooks/requests/it4SendAnswerQuestion/it4SendAnswerQuestion";
import useStoreResetter from "@/hooks/useStoreResetter/useStoreResetter";
import {
    useAnalyticsEventPrePrice,
    useAnalyticsEventImei,
    useAnalyticsEventTiModel,
} from "@/hooks/useAnalytics/useAnalytics";
import { deviceGradeNormalize } from "@/helpers/deviceGrade";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";

const TIWStore = useTIWStore();
const ChooseDeviceStore = useChooseDeviceStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();

const loadingToNext = ref<boolean>(false);

const onEditHandler = () => {
    if (loadingToNext.value) return;

    HowYouRateDeviceStore.restoreToDefault();
    ChooseDeviceStore.restoreToDefault();
    TIWStore.switchStep("chooseDevice");
    useAnalyticsEventTiModel();
};

const selectRateHandler = async (e: MouseEvent, cid: string) => {
    loadingToNext.value = true;

    useAnalyticsEventPrePrice();

    // Ответить на вопрос о статусах оценки
    const answerQuestionData = await it4SendAnswerQuestion({
        token: TIWStore.WIDGET_DATA.token, // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId, // deviceID сдаваемого устройства
        lang: TIWStore.standardizedLocale,
        c: cid,
        ans: "",
        questionId: HowYouRateDeviceStore.questionData.questionId,
        from: "3",
    });

    // Устанавливаем значение грейда из ответа ответа на вопрос
    HowYouRateDeviceStore.grade = deviceGradeNormalize(answerQuestionData.device.grade);

    // Устанавливаем описание значения грейда "Как новый", "С дефектами"
    HowYouRateDeviceStore.rate = useTranslate(
        `tiw__grades__${
            GRADES_DATA[answerQuestionData.device.grade].gradeCode
        }_stateInfo`
    );

    useTradeInCalculation({
        tradeInPrice: answerQuestionData.device.price,
    });

    loadingToNext.value = false;

    // Потом продвигаемся на шаг вперед
    HowYouRateDeviceStore.isBack = false;
};

const clickToBackHandler = async () => {
    HowYouRateDeviceStore.isBack = true;
};

const clickToNext = async () => {
    TIWStore.switchStep("enterImei");
    useAnalyticsEventImei();
};

const clickRestartHandler = async () => {
    useStoreResetter();
    TIWStore.INJECTED_TIW.current_api.onDrop();
    TIWStore.switchStep("chooseDevice");
    useAnalyticsEventTiModel();
};

const clickToClose = async () => {
    TIWStore.closeWidget();
    useStoreResetter();
    TIWStore.INJECTED_TIW.current_api.onDrop();
};
</script>

<style lang="scss" src="./howYouRateDevice.scss"></style>
