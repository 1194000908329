<template>
    <Modal>
        <template #header>
            <h2 class="tiw__step__successfulEvaluation__header">
                <AwardIcon />
                {{ useTranslate("tiw__successfulEvaluation__title") }}
            </h2>
            <p>
                {{ useTranslate("tiw__successfulEvaluation__subtitle") }}
            </p>
        </template>

        <div class="tiw__step__successfulEvaluation">
            <form name="successfulEvaluationForm">
                <CalculatedDevice
                    :calculated-data="
                        HowYouRateDeviceStore.calculatedDeviceData
                    "
                />

                <!-- Поле "IMEI" -->
                <Field>
                    <template #title>
                        <span>
                            {{
                                useTranslate(
                                    "tiw__successfulEvaluation__imei_title"
                                )
                            }}
                        </span>
                    </template>
                    <template #body>
                        <Input
                            v-model="ChooseDeviceStore.field_imei.value"
                            type="text"
                            name="imei"
                            disabled
                        />
                    </template>
                </Field>

                <!-- Поле "ФИО" -->
                <Field :is-error="!fioValidation.status">
                    <template #title>
                        <span>
                            {{
                                useTranslate(
                                    "tiw__successfulEvaluation__fio_title"
                                )
                            }}
                        </span>
                    </template>
                    <template #hintTop>
                        <span>
                            {{ useTranslate("tiw__successfulEvaluation__fio") }}
                        </span>
                    </template>
                    <template #body>
                        <Input
                            v-model="SuccessfulEvaluationStore.fields.fio"
                            type="text"
                            name="fio"
                            maxlength="50"
                            :disabled="loadingToNext"
                        />
                    </template>
                    <template v-if="!fioValidation.status" #hintBottom>
                        <span>
                            {{ fioValidation.message }}
                        </span>
                    </template>
                </Field>

                <!-- Поле "ИНН" -->
                <Field :is-error="!innValidation.status">
                    <template #hintTop>
                        <span>
                            {{ useTranslate("tiw__successfulEvaluation__inn") }}
                        </span>
                    </template>
                    <template #body>
                        <Input
                            v-model="SuccessfulEvaluationStore.fields.inn"
                            type="numeric"
                            name="fio"
                            maxlength="12"
                            :disabled="loadingToNext"
                        />
                    </template>
                    <template v-if="!innValidation.status" #hintBottom>
                        <span>
                            {{ innValidation.message }}
                        </span>
                    </template>
                </Field>

                <!-- Поле "E-mail" -->
                <Field :is-error="!emailValidation.status">
                    <template #hintTop>
                        <span>
                            {{
                                useTranslate("tiw__successfulEvaluation__email")
                            }}
                        </span>
                    </template>
                    <template #body>
                        <Input
                            v-model="SuccessfulEvaluationStore.fields.email"
                            type="text"
                            name="email"
                            :placeholder="useTranslate('tiw__notRequired')"
                            :disabled="loadingToNext"
                        />
                    </template>
                    <template v-if="!emailValidation.status" #hintBottom>
                        <span>
                            {{ emailValidation.message }}
                        </span>
                    </template>
                </Field>

                <!-- Поле "Телефон" -->
                <Field :is-error="!phoneValidation.status">
                    <template #hintTop>
                        <span>
                            {{
                                useTranslate("tiw__successfulEvaluation__phone")
                            }}
                        </span>
                    </template>
                    <template #body>
                        <Input
                            v-model="SuccessfulEvaluationStore.fields.phone"
                            v-maska="phoneMaskOptions"
                            type="text"
                            name="phone"
                            placeholder="+7 (___) ___-__-__"
                            :disabled="loadingToNext"
                        />
                    </template>
                    <template v-if="!phoneValidation.status" #hintBottom>
                        <span>
                            {{ phoneValidation.message }}
                        </span>
                    </template>
                </Field>

                <!-- Чекбокс "Персональные данные" -->
                <Checkbox v-model="SuccessfulEvaluationStore.fields.personal">
                    <span
                        v-html="
                            useTranslate(
                                'tiw__successfulEvaluation__checkbox_personal'
                            )
                        "
                    />
                </Checkbox>

                <!-- Чекбокс "Я обязуюсь" -->
                <Checkbox v-model="SuccessfulEvaluationStore.fields.duty">
                    <span>
                        {{
                            useTranslate(
                                "tiw__successfulEvaluation__checkbox_duty_head"
                            )
                        }}:
                    </span>
                    <ul>
                        <li>
                            {{
                                useTranslate(
                                    "tiw__successfulEvaluation__checkbox_duty_step1"
                                )
                            }}
                        </li>
                        <li>
                            {{
                                useTranslate(
                                    "tiw__successfulEvaluation__checkbox_duty_step2"
                                )
                            }}
                        </li>
                        <li>
                            {{
                                useTranslate(
                                    "tiw__successfulEvaluation__checkbox_duty_step3"
                                )
                            }}
                        </li>
                    </ul>
                    <span>
                        {{
                            useTranslate(
                                "tiw__successfulEvaluation__checkbox_duty_footer"
                            )
                        }}
                    </span>
                </Checkbox>
            </form>

            <p
                class="tiw__step__successfulEvaluation__warn"
                v-html="useTranslate('tiw__successfulEvaluation__warn')"
            />

            <div class="tiw_group tiw_group-sb">
                <!-- <Button
                    type="button"
                    variant="skeleton"
                    class="tiw__step__successfulEvaluation__btn-back"
                    @click="onRestart"
                >
                    <RefreshIcon />
                    <span>Начать заново</span>
                </Button> -->
                <Button
                    type="button"
                    class="tiw__step__successfulEvaluation__btn-next tiw__btn-next"
                    :disabled="!isAcceptedToNext || loadingToNext"
                    :loading="loadingToNext"
                    @click="clickToNext"
                >
                    <span>
                        {{
                            useTranslate(
                                "tiw__successfulEvaluation__toContract"
                            )
                        }}
                    </span>
                </Button>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "SuccessfulEvaluation",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [SuccessfulEvaluation]
 * Модальное окно / экран "Ура! Все готово!"
 */

// Components
import Modal from "@/components/modal/modal.vue";
import CalculatedDevice from "@/components/calculatedDevice/calculatedDevice.vue";
import Button from "@/ui/button/button.vue";
import Input from "@/ui/input/input.vue";
import Checkbox from "@/ui/checkbox/checkbox.vue";
import Field from "@/ui/field/field.vue";

// Icons
import AwardIcon from "@/ui/icons/award.vue";
// import RefreshIcon from "@/ui/icons/refresh.vue";

// Types
import type { MaskInputOptions } from "maska";

// Hooks
import { ref, computed, reactive } from "vue";
import { vMaska } from "maska/vue";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import useValidate from "@/hooks/useValidate";
import it4CreateContract from "@/hooks/requests/it4CreateContract/it4CreateContract";
import { useAnalyticsEventAgreement } from "@/hooks/useAnalytics/useAnalytics";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useSuccessfulEvaluationStore } from "@/stores/successfulEvaluation/successfulEvaluation";

const TIWStore = useTIWStore();
const SuccessfulEvaluationStore = useSuccessfulEvaluationStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();
const ChooseDeviceStore = useChooseDeviceStore();

// Валидация поля "ИНН"
const innValidation = computed(() => {
    if (!SuccessfulEvaluationStore.fields.inn) {
        return {
            status: true,
            message: "ok",
        };
    }
    const valiDate = useValidate({
        target: "inn",
        value: SuccessfulEvaluationStore.fields.inn,
    });
    return valiDate;
});

// Валидация поля "ФИО"
const fioValidation = computed(() => {
    if (!SuccessfulEvaluationStore.fields.fio) {
        return {
            status: true,
            message: "ok",
        };
    }
    const valiDate = useValidate({
        target: "fio",
        value: SuccessfulEvaluationStore.fields.fio,
    });
    return valiDate;
});

// Валидация поля "email"
const emailValidation = computed(() => {
    if (!SuccessfulEvaluationStore.fields.email) {
        return {
            status: true,
            message: "ok",
        };
    }
    const valiDate = useValidate({
        target: "email",
        value: SuccessfulEvaluationStore.fields.email,
    });
    return valiDate;
});

// Валидация поля "Телефон"
const phoneValidation = computed(() => {
    if (!SuccessfulEvaluationStore.fields.phone) {
        return {
            status: true,
            message: "ok",
        };
    }
    const valiDate = useValidate({
        target: "phone",
        value: SuccessfulEvaluationStore.fields.phone,
    });
    return valiDate;
});

// Маска автозаполнение номера телефона поля "Телефон"
const phoneMaskOptions = reactive<MaskInputOptions>({
    mask: "+7 (___) ___-__-__",
    tokens: {
        _: {
            pattern: /[0-9]/,
        },
    },
    eager: true,
});

const isAcceptedToNext = computed<boolean>(() => {
    const validationStatus = [fioValidation.value, innValidation.value].every(
        (el) => el.status
    );

    return validationStatus && SuccessfulEvaluationStore.isAcceptedToNext;
});

const loadingToNext = ref<boolean>(false);

const clickToNext = async () => {
    loadingToNext.value = true;

    useAnalyticsEventAgreement();

    const ContractData = await it4CreateContract({
        token: TIWStore.WIDGET_DATA.token,
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
        lang: TIWStore.standardizedLocale,
        firstName: SuccessfulEvaluationStore.destructuredFio.firstName,
        lastName: SuccessfulEvaluationStore.destructuredFio.lastName,
        middleName: SuccessfulEvaluationStore.destructuredFio.middleName,
        mobile: SuccessfulEvaluationStore.fields.phone,
        doc: SuccessfulEvaluationStore.fields.inn,
    });

    SuccessfulEvaluationStore.contractData = ContractData;
    loadingToNext.value = false;
    TIWStore.switchStep("addToCart");
};
</script>

<style lang="scss" src="./successfulEvaluation.scss"></style>
