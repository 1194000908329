// Hooks
import it4PhotoDownloader from "@/hooks/requests/it4PhotoDownloader/it4PhotoDownloader";
import it4getPhotoSetId from "@/hooks/requests/it4GetPhotoSetId/it4GetPhotoSetId";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";

/** Загрузить фото в ИТ4 */
export default async function usePhotoDownloader(
    formRef: HTMLFormElement
): Promise<void> {
    const TIWStore = useTIWStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();
    const DownloadPhotosStore = useDownloadPhotosStore();

    const payloadFormData: FormData = new FormData(formRef);

    payloadFormData.set("token", TIWStore.WIDGET_DATA.token);
    payloadFormData.set("lang", TIWStore.standardizedLocale);

    const files: FormDataEntryValue[] = payloadFormData.getAll("file");

    payloadFormData.delete("file");

    DownloadPhotosStore.fieldsLineData.forEach((lineData, ind) => {
        if (files[ind] instanceof File) {
            if (files[ind].size) {
                payloadFormData.append("file", files[ind]);
                payloadFormData.append("lineId", lineData.lineId);
            }
        }
    });

    // console.log(payloadFormData.getAll("file"));

    const loadRequestData = await it4PhotoDownloader(
        HowYouRateDeviceStore.registeringDeviceData.deviceId,
        payloadFormData
    );

    DownloadPhotosStore.fieldsLineData = loadRequestData;

    const photoSetData = await it4getPhotoSetId(
        HowYouRateDeviceStore.registeringDeviceData.deviceId,
        {
            token: TIWStore.WIDGET_DATA.token,
            lang: TIWStore.standardizedLocale,
        }
    );
    DownloadPhotosStore.photoSetData = photoSetData;

    // console.log(loadRequestData);
}
