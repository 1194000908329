// Hooks
import it4PhotoDownloader from "@/hooks/requests/it4PhotoDownloader/it4PhotoDownloader";
// import it4getPhotoSetId from "@/hooks/requests/it4GetPhotoSetId/it4GetPhotoSetId";
import it4SetPhotoSetStatus from "@/hooks/requests/it4SetPhotoSetStatus/it4SetPhotoSetStatus";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";
import { useEvaluationResultStore } from "@/stores/evaluationResult/evaluationResult";

/** Дозагрузить фото в ИТ4 */
export default async function usePhotoReloader(
    formRef: HTMLFormElement
): Promise<boolean> {
    const TIWStore = useTIWStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();
    const DownloadPhotosStore = useDownloadPhotosStore();
    const EvaluationResultStore = useEvaluationResultStore();

    if (EvaluationResultStore.fieldNamesData.length === 0) return false;

    const payloadFormData: FormData = new FormData(formRef);

    payloadFormData.set("token", TIWStore.WIDGET_DATA.token);
    payloadFormData.set("lang", TIWStore.standardizedLocale);

    const files: FormDataEntryValue[] = payloadFormData.getAll("file");

    payloadFormData.delete("file");

    EvaluationResultStore.fieldsLineData.forEach((lineData, ind) => {
        if (files[ind] instanceof File) {
            if (files[ind].size > 0) {
                console.log(files[ind].size);
                payloadFormData.append("file", files[ind]);
                payloadFormData.append("lineId", lineData.lineId);
            }
        }
    });

    console.log(payloadFormData.getAll("file"));

    if (!payloadFormData.getAll("file").length) {
        return false;
    }

    // Обрубаем загрузку
    // return true;

    const loadRequestData = await it4PhotoDownloader(
        HowYouRateDeviceStore.registeringDeviceData.deviceId,
        payloadFormData
    );

    await it4SetPhotoSetStatus({
        token: TIWStore.WIDGET_DATA.token,
        lang: TIWStore.standardizedLocale,
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
        photosetId: DownloadPhotosStore.photoSetData.photosetId,
        status: "TO_GRADING",
    });

    console.log(loadRequestData);
    return true;
}
