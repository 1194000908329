<template>
    <Modal class="tiw__step__addToCart">
        <template #header>
            <h2 class="tiw__step__addToCart__header">
                <AwardIcon />
                {{ useTranslate("tiw__addToCart__title") }}
            </h2>
            <p
                v-html="
                    useTranslate('tiw__addToCart__subtitle').replace(
                        '[[NUMBER]]',
                        SuccessfulEvaluationStore.fields.phone
                    )
                "
            ></p>
        </template>

        <p
            class="tiw__step__addToCart__info tiw__step__addToCart__info_mob tiw__text tiw_fs-12 tiw_usn"
        >
            <span>
                {{ useTranslate("tiw__addToCart__notContract") }}
            </span>
            <br />
            <Button color="link" variant="link" type="button" class="tiw_fs-12">
                <span>
                    {{ useTranslate("tiw__addToCart__sendAgain") }}
                </span>
            </Button>
        </p>

        <div class="tiw__step__addToCart__body">
            <!-- Чекбокс "Я подписал договор" -->
            <!-- <Checkbox v-model="SuccessfulEvaluationStore.fields.acceptContract">
                <span> Я подписал договор </span>
            </Checkbox> -->
            <template v-if="!isSigned">
                <div class="tiw__step__addToCart__warn">
                    {{ useTranslate("tiw__addToCart__warn") }}
                </div>
            </template>
            <Button
                type="button"
                :disabled="loadingToNext"
                :loading="loadingToNext"
                class="tiw__step__addToCart__btn-send"
                @click="onAddToCart"
            >
                <span>
                    {{ useTranslate("tiw__addToCart__addToCart") }}
                </span>
            </Button>
        </div>
        <p
            class="tiw__step__addToCart__info tiw__step__addToCart__info_desk tiw__text tiw_fs-12 tiw_usn"
        >
            <span>
                {{ useTranslate("tiw__addToCart__notContract") }}
            </span>
            <br />
            <Button
                color="link"
                variant="link"
                type="button"
                class="tiw_fs-12"
                :disabled="loadingToNext"
                @click="sendSmsAgain"
            >
                <span>
                    {{ useTranslate("tiw__addToCart__sendAgain") }}
                </span>
            </Button>
        </p>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "AddToCart",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [AddToCart]
 * Модальное окно / экран "Ура! Все готово! Добавить в корзину"
 */

// Components
import Modal from "@/components/modal/modal.vue";
import Button from "@/ui/button/button.vue";
// import Checkbox from "@/ui/checkbox/checkbox.vue";

// Icons
import AwardIcon from "@/ui/icons/award.vue";

// Hooks
import { suck } from "@/helpers/consoleColors";
import useStoreResetter from "@/hooks/useStoreResetter/useStoreResetter";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import it4CheckContractSign from "@/hooks/requests/it4CheckContractSign/it4CheckContractSign";
import it4SendContractToSign from "@/hooks/requests/it4SendContractToSign/it4SendContractToSign";
import { useAnalyticsEventAddToCart } from "@/hooks/useAnalytics/useAnalytics";

// Store
import { ref } from "vue";
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useSuccessfulEvaluationStore } from "@/stores/successfulEvaluation/successfulEvaluation";

const TIWStore = useTIWStore();
const ChooseDeviceStore = useChooseDeviceStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();
const SuccessfulEvaluationStore = useSuccessfulEvaluationStore();

const isSigned = ref<boolean>(true);
const loadingToNext = ref<boolean>(false);

const onAddToCart = async () => {
    loadingToNext.value = true;

    const ContractSignInfo = await it4CheckContractSign({
        token: TIWStore.WIDGET_DATA.token, // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId, // deviceID сдаваемого устройства
    });

    if (ContractSignInfo.signed) {
        console.log("%cCONTRACT IS SIGNED", suck, ContractSignInfo.signId);

        useAnalyticsEventAddToCart();

        isSigned.value = true;

        TIWStore.INJECTED_TIW.current_api.onCart({
            brand: ChooseDeviceStore.brandName,
            device_id: HowYouRateDeviceStore.registeringDeviceData.deviceId,
            device_imei: ChooseDeviceStore.field_imei.value,
            trade_up_model: TIWStore.INJECTED_GOOD.panelTitle,
            trade_up_price: TIWStore.INJECTED_GOOD.panelPrice,
            model: ChooseDeviceStore.deviceChain.join(" "),
            price: HowYouRateDeviceStore.calculatedDeviceData.panelCost,
            trade_in_price:
                typeof HowYouRateDeviceStore.calculatedDeviceData
                    .panelTradeInSale === "string"
                    ? HowYouRateDeviceStore.calculatedDeviceData
                          .panelTradeInSale
                    : HowYouRateDeviceStore.calculatedDeviceData
                          .panelTradeInSale.tradeInSale,
            top_up_price: HowYouRateDeviceStore.calculatedDeviceData
                .panelActionSale
                ? HowYouRateDeviceStore.calculatedDeviceData.panelActionSale
                      .actionSale
                : null,
            grade: HowYouRateDeviceStore.registeringDeviceData.grade,
            certificate: ContractSignInfo.certificateNumber,
            signedDocumentUrl: ContractSignInfo.signedDocumentUrl,
            final_price: true,
            first_name: SuccessfulEvaluationStore.destructuredFio.firstName,
            last_name: SuccessfulEvaluationStore.destructuredFio.lastName,
            email: SuccessfulEvaluationStore.fields.email,
            phone: SuccessfulEvaluationStore.fields.phone,
        });

        TIWStore.closeWidget();
        useStoreResetter();
    } else {
        isSigned.value = false;
    }

    loadingToNext.value = false;
};

const sendSmsAgain = async () => {
    loadingToNext.value = true;

    await it4SendContractToSign({
        token: TIWStore.WIDGET_DATA.token, // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId, // deviceID сдаваемого устройства
        contractNumber: SuccessfulEvaluationStore.contractData.contractNumber, // Номер контракта
    });

    isSigned.value = true;
    loadingToNext.value = false;
};
</script>

<style lang="scss" src="./addToCart.scss"></style>
