<template>
    <Container>
        <section :class="containerSectionClassList">
            <InitWidgetLoader v-if="TIWStore.IS_INIT_WIDGET_LOADING" />

            <!-- <BuyDevice v-if="TIWStore.isCurrentStep('buyDevice')" /> -->
            <ChooseDevice v-if="TIWStore.isCurrentStep('chooseDevice')" />
            <HowYouRateDevice
                v-if="TIWStore.isCurrentStep('howYouRateDevice')"
            />
            <EnterImei v-if="TIWStore.isCurrentStep('enterImei')" />
            <DownloadPhotos
                v-show="TIWStore.isCurrentStep('downloadPhotos')"
            />
            <OperatorExecuting
                v-if="TIWStore.isCurrentStep('operatorExecuting')"
            />
            <SuccessfulEvaluation
                v-if="TIWStore.isCurrentStep('successfulEvaluation')"
            />
            <EvaluationResult
                v-if="TIWStore.isCurrentStep('evaluationResult')"
            />
            <!-- <SendSmsContract
                v-if="TIWStore.isCurrentStep('sendSmsContract')"
            /> -->
            <AddToCart v-if="TIWStore.isCurrentStep('addToCart')" />
            <!-- Решили пока что убрать -->
            <!-- <ThanksForOrder v-if="TIWStore.isCurrentStep('thanksForOrder')" /> -->
        </section>

        <template #after-wrapper>
            <ImageFullScreen />
        </template>
    </Container>
</template>

<script lang="ts">
export default {
    name: "TradeInWidget",
    inject: ["appInstance", "widget"],
};
</script>

<script setup lang="ts">
// Global styles
import "@/scss/global.scss";

// Components
import Container from "@/components/container/container.vue";
import ImageFullScreen from "@/components/imageFullScreen/imageFullScreen.vue";

// Modal Steps
import InitWidgetLoader from "@/components/modalSteps/initWidgetLoader/initWidgetLoader.vue";
// import BuyDevice from "@/components/modalSteps/buyDevice/buyDevice.vue";  // Решили временно убрать
import ChooseDevice from "@/components/modalSteps/chooseDevice/chooseDevice.vue";
import HowYouRateDevice from "@/components/modalSteps/howYouRateDevice/howYouRateDevice.vue";
import EnterImei from "@/components/modalSteps/enterImei/enterImei.vue";
import DownloadPhotos from "@/components/modalSteps/downloadPhotos/downloadPhotos.vue"; // [V-SHOW] что бы не сбрасывались данные нативного input.file при размонтировании компонента из DOM
import OperatorExecuting from "@/components/modalSteps/operatorExecuting/operatorExecuting.vue";
import SuccessfulEvaluation from "@/components/modalSteps/successfulEvaluation/successfulEvaluation.vue";
import EvaluationResult from "@/components/modalSteps/evaluationResult/evaluationResult.vue";
// import SendSmsContract from "@/components/modalSteps/sendSmsContract/sendSmsContract.vue"; // Решили временно убрать
import AddToCart from "@/components/modalSteps/addToCart/addToCart.vue";
// import ThanksForOrder from "@/components/modalSteps/thanksForOrder/thanksForOrder.vue"; // Решили временно убрать

// Hooks
import { onMounted, computed } from "vue";
import { suck, warn } from "@/helpers/consoleColors";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";

const TIWStore = useTIWStore();

onMounted(() => {
    console.log("%c[TIWidget] -> APP is Mounted", suck);
    console.log(
        "%c[TIWidget] -> press to any [Trade-In with Good] button",
        warn
    );
    TIWStore.INJECTED_TIW.current_api.onLoad();
});

const containerSectionClassList = computed<string[]>(() => {
    return [
        'tiw__modal',
        'tiw__step',
    ];
});
</script>
