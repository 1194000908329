<template>
    <aside :class="classes" v-bind="$attrs">
        <div class="tiw__backdrop" />
        <div class="tiw__wrapper">
            <slot />
        </div>
        <slot name="after-wrapper"></slot>
    </aside>
    <RolledStatus
        :header="OperatorExecutingStore.currentStatusMessages.header"
        :status="OperatorExecutingStore.status"
        :percent="OperatorExecutingStore.percent"
    />
</template>

<script lang="ts">
export default {
    name: "ContainerComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Container]
 * Обертка для модального окна
 */

// Hooks
import { computed } from "vue";
// import useExecutingStatus from "@/hooks/useExecutingStatus";

// Components
import RolledStatus from "@/components/rolledStatus/rolledStatus.vue";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useOperatorExecutingStore } from "@/stores/operatorExecuting/operatorExecuting";

const TIWStore = useTIWStore();
const OperatorExecutingStore = useOperatorExecutingStore();

const classes = computed(() => {
    return [
        "tiw",
        "tiw__container",
        {
            tiw_open: !TIWStore.IS_CLOSE,
            tiw_open_rollDown: TIWStore.IS_ROLL,
            tiw_transition: TIWStore.IS_TRANSITION,
            tiw_close: TIWStore.IS_CLOSE,
        },
    ];
});
</script>

<style lang="scss" src="./container.scss"></style>
